<template>
    <div id="homepage" class="frontpage">
        <VueSlickCarousel v-bind="CarouselSetting">
            <div class="item-slider">
                <div class="item-container" v-bind:style="{'background-image': 'url(' + require('../../assets/images/slider/bg_slider_1.png') + ')'}">
                    <div class="content-slider">
                        <div class="container">
                            <div class="col-md-6">
                                <h1>School Of Management</h1>
                                <p>Konsentrasi manajemen jejaring bisnis berkomitmen menjadi bidang minat yang berkualitas dan terpecaya dalam pembelajaran</p>
                                <router-link to="/about-us" class="btn btn-white mt-5">More Info</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="social-slider">
                        <div class="container">
                            <div class="col-md-12">
                                <ul>
                                    <li><a href="https://www.instagram.com/ubayasm/" target="_blank"><Icon icon="akar-icons:instagram-fill" /></a></li>
                                    <li><a href="https://web.facebook.com/ubaya.school.management/" target="_blank"><Icon icon="brandico:facebook-rect" /></a></li>
                                    <li><a href="https://fbeubaya.id" target="_blank"><Icon icon="mdi:web" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-slider">
                <div class="item-container" v-bind:style="{'background-image': 'url(' + require('../../assets/images/slider/bg_slider_2.png') + ')'}">
                    <div class="content-slider">
                        <div class="container">
                            <div class="col-md-6">
                                <h1>synergetic collaborative</h1>
                                <p>Konsentrasi manajemen jejaring bisnis berkomitmen menjadi bidang minat yang berkualitas dan terpecaya dalam pembelajaran</p>
                                <router-link to="/about-us" class="btn btn-white mt-5">More Info</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="social-slider">
                        <div class="container">
                            <div class="col-md-12">
                                <ul>
                                    <li><a href="https://www.instagram.com/ubayasm/" target="_blank"><Icon icon="akar-icons:instagram-fill" /></a></li>
                                    <li><a href="https://web.facebook.com/ubaya.school.management/" target="_blank"><Icon icon="brandico:facebook-rect" /></a></li>
                                    <li><a href="https://fbeubaya.id" target="_blank"><Icon icon="mdi:web" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
        <section id="latest-news" v-if="latestnews.length > 0">
            <div class="container">
                <div class="row py-5">
                    <div class="col-12 mb-5">
                        <center>
                            <p class="subtitle">Berita Terbaru</p>
                            <h2 class="title">Berita Terbaru Seputar PSMFBE</h2>
                        </center>
                    </div>
                    <div class="col-md-6 col-lg-4 articles" v-for="news in latestnews" :key="news.id">
                        <img :src="news.images" alt="" class="preview">
                        <small><Icon icon="bi:calendar" class="d-inline mr-2" />{{ news.date }}</small>
                        <h3 class="mb-2 mt-1">{{ news.title }}</h3>
                        <p class="mb-2 ellipsis-3" v-html="news.description"></p>
                        <router-link to="/login">Selengkapnya <Icon icon="dashicons:arrow-right-alt2" class="d-inline" /></router-link>
                    </div>
                </div>
            </div>
        </section>
        <section id="latest-event" v-if="latestevent.length > 0">
            <div class="container">
                <div class="row py-5">
                    <div class="col-12 mb-5">
                        <center>
                            <p class="subtitle">Acara</p>
                            <h2 class="title">Acara INFINITY Di Bulan Ini</h2>
                        </center>
                    </div>
                    <div class="col-md-12 col-lg-6 mb-3 pr-5 articles d-flex" v-for="event in latestevent" :key="event.nomor">
                        <div>
                            <a :href="'https://fbeubaya.id/events/detail/'+event.nomor" target="_blank">
                                <img :src="'https://fbeubaya.id/assets/img/events/'+event.image" alt="" class="preview-event">
                            </a>
                        </div>
                        <div class="pl-4">
                            <a :href="'https://fbeubaya.id/events/detail/'+event.nomor" target="_blank">
                                <h3 class="mb-2 mt-1 pr-5">{{ event.title }}</h3>
                                <p class="mb-2 ellipsis-3 pr-5" v-html="event.description"></p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="featured" style="background: #F7FAFD;">
            <div class="container">
                <div class="col-12 py-3">
                    <div class="row mb-5">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <center>
                                <h2 class="title mb-3">Mengapa Infinity?</h2>
                                <p>Infinity hadir sebagai terobosan pembelajaran baru yang menarik, tanpa terbatas oleh ruang kelas dan jam perkuliahan.</p>
                            </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="clarity:group-line" />
                                    <h3>Berbasis Komunitas</h3>
                                    <p>Belajar akan terasa begitu menyenangkan ketika kita dapat berdiskusi dengan banyak teman</p>
                                </center>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="fluent:chart-person-20-regular" />
                                    <h3>Berdiskusi Langsung dengan Para Pakar</h3>
                                    <p>Infinity menghadirkan para pakar yang siap untuk berdiskusi dan berkoloaborasi bersama.</p>
                                </center>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="cil:book" />
                                    <h3>Mendapatkan Ilmu melampaui Kelas</h3>
                                    <p>Dengan adanya resources yang lengkap, ilmu dapat didapatkan kapanpun dan dimanapun, melampaui kelas kuliah. </p>
                                </center>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="uiw:like-o" />
                                    <h3>Peluang Bekerja & Berkarya</h3>
                                    <p>Infinity merupakan tempat kolaborasi antara mahasiswa, dosen dan pelaku usaha / bisnis di Industri yang sesuai. Peluang untuk berkarya dan mendapatkan pekerjaan akan semakin besar.</p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="latest-event" v-if="activegroup.length > 0">
            <div class="container">
                <div class="row py-5">
                    <div class="col-12 mb-5">
                        <center>
                            <p class="subtitle">Grup</p>
                            <h2 class="title">Grup Aktif INFINITY</h2>
                        </center>
                        <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid equal-cols mt-5">
                            <CardBlog v-for="group in activegroup" :key="group.id" :data="group" :styles="'group'" :from="'frontpage'" />
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section id="featured" style="background: #DBEDFF;">
            <div class="container">
                <div class="row py-5">
                    <div class="col-12 p-5 card" style="border-bottom-left-radius: 50px;">
                        <div class="row align-items-center">
                            <div class="col-md-7">
                                <p class="subtitle">Kontak Kami</p>
                                <h2 class="title mb-3">Hubungi Kami Untuk Informasi Lebih Lanjut</h2>
                                <p>Jika Anda memiliki pertanyaan atau pesan yang ingin disampaikan, jangan ragu untuk menghubungi kami. Silakan klik tombol berikut untuk mengirimkan pesan kepada kami.</p>
                                <router-link to="/contact-us" class="btn btn-primary mt-4">Kontak Kami</router-link>
                            </div>
                            <div class="col-md-5">
                                <img src="@/assets/images/contact_us.png" alt="CONTACT US">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import { Icon } from '@iconify/vue2'
    import axios from 'axios'
    import CardBlog from '@/components/CardBlog'

    export default {
        name: 'Landingpage',
        components: {
            Icon,
            CardBlog,
            VueSlickCarousel
        },
        data () {
            return {
                CarouselSetting: {
                    arrows: false,
                    dots: true,
                    fade: true,
                    cssEase: 'linear',
                    infinite: true,
                    speed: 500,
                    draggable: true
                },
                latestnews: [],
                latestevent: [],
                activegroup: []
            }
        },
        created () {
            this.fetchData()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            fetchData () {
                const $this = this
                axios.get('latest_news?limit=3').then(function (response) {
                    $this.latestnews = response.data.result.data
                })
                axios.get('https://fbeubaya.id/api/get_events_all/1').then(function (response) {
                    $this.latestevent = response.data
                })
                axios.get('active_group?limit=4').then(function (response) {
                    $this.activegroup = response.data.result
                })
            }
        }
    }
</script>
